import React from 'react';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { colors, breakpoints } from '../../theme';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faHome, faCalendarWeek, faTag, faMapSigns, faImages } from '@fortawesome/pro-light-svg-icons'

const Navigation = props => {
    const { classes } = props;
    const [drawerIsOpen, setDrawerOpenState] = React.useState(false);

    const handleClick = event => {
        setDrawerOpenState(true);
    };

    const toggleDrawer = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpenState(false);
    };

    const renderMenuItems = () => {
        return (<>
            <Link to="/" className={classes.link}>
                <div className={clsx(classes.navigationItem, classes.mobileGrid, 'navigationItem')}>
                    <FontAwesomeIcon icon={faHome} /> Inicio
                </div>
            </Link>
            <Link to="/reservations" className={classes.link}>
                <div className={clsx(classes.navigationItem, classes.mobileGrid, 'navigationItem')}>
                    <FontAwesomeIcon icon={faCalendarWeek} /> Reservaciones
                </div>
            </Link>
            <Link to="/prices" className={classes.link}>
                <div className={clsx(classes.navigationItem, classes.mobileGrid, 'navigationItem')}>
                    <FontAwesomeIcon icon={faTag} /> Precios
                </div>
            </Link>
            <Link to="/directions" className={classes.link}>
                <div className={clsx(classes.navigationItem, classes.mobileGrid, 'navigationItem')}>
                    <FontAwesomeIcon icon={faMapSigns} /> Direcciones
                </div>
            </Link>
            <Link to="/pictures" className={classes.link}>
                <div className={clsx(classes.navigationItem, classes.mobileGrid, 'navigationItem')}>
                    <FontAwesomeIcon icon={faImages} /> Fotos
                </div>
            </Link>
        </>);
    };

    return (<div className={classes.main}>
        <SwipeableDrawer
            anchor="right"
            open={drawerIsOpen}
            onClose={toggleDrawer()}
            onOpen={toggleDrawer()}
            className={classes.drawer}
        >
            <div className={classes.mobileContainer}>
                {renderMenuItems()}
            </div>
        </SwipeableDrawer>
        <div className={classes.menuIconContainer} onClick={handleClick}>
            <Fab size="small" color="primary" aria-label="add" className={classes.margin}>
                <FontAwesomeIcon icon={faBars} />
            </Fab>
        </div>
        <div className={classes.itemsContainer}>
            {renderMenuItems()}
        </div>
    </div>);
}

const styles = theme => ({
    main: {
        height: 31,
        backgroundColor: colors.silver,
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.5)',
        [theme.breakpoints.down(breakpoints.xs)]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
        },
    },
    navigationItem: {
        paddingLeft: 12,
        paddingRight: 12,
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: colors.darkest,
        cursor: 'pointer',
    },
    itemsContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down(breakpoints.sm)]: {
            justifyContent: 'space-around',
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
            display: 'none',
        },
    },
    mobileContainer: {
        padding: 10,
        paddingLeft: 5,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    menuIconContainer: {
        [theme.breakpoints.up(breakpoints.xs)]: {
            display: 'none',
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
            display: 'flex',
        },
    },
    mobileGrid: {
        [theme.breakpoints.down(breakpoints.xs)]: {
            display: 'grid',
            gridTemplateColumns: '30px auto',
            alignItems: 'center'
        },
    },
    drawer: {
        width: 100,
    },
    link: {
        textDecoration: 'none',
    },
});
export default withStyles(styles)(Navigation);