import React from 'react';
import { withStyles } from '@material-ui/styles';
import { breakpoints } from '../../theme';

const MainContainer = props => {
    const { classes, children } = props;
    return (<div className={classes.root}>
        {children}
    </div>);
}

const styles = theme => ({
    root: {
        marginTop: 40,
        marginBottom: 40,
        borderRadius: 2,
        boxShadow: '1px 2px 6px -1px rgba(0,0,0,0.75)',
        backgroundColor: 'white',
        [theme.breakpoints.up(breakpoints.md)]: {
            width: 1280,
        },
        [theme.breakpoints.down(breakpoints.md)]: {
            width: 960,
        },
        [theme.breakpoints.down(breakpoints.sm)]: {
            width: 600,
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
            borderRadius: 0,
        }
    }
});

export default withStyles(styles)(MainContainer);