import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { colors, breakpoints } from '../theme';
import { numberFormat } from '../utils';


const PlanBox = props => {
    const { plan, classes } = props;
    const { title, minPeople, maxPeople, variations } = plan;
    const titleDescription = (() => {
        if (minPeople !== maxPeople)
            return `(De ${minPeople} a ${maxPeople} personas)`;
        return `(Para ${maxPeople} personas)`;
    })();
    const renderPlan = () => {
        return variations.map((el, idx) => {
            return <>
                <div className={clsx(classes.row, (idx % 2 - 1) && classes.highlight)}>
                    {el.nameOfPlan}
                </div>
                <div className={clsx(classes.row, classes.priceColumn,
                    (idx % 2 - 1) && classes.highlight)}>
                    {numberFormat(el.price, 2)}
                </div>
            </>
        })
    }
    return (
        <div className={classes.container}>
            <div className={classes.table}>
                <div className={clsx(classes.tableTitle, classes.row)}>{`${title} ${titleDescription}`}</div>
                <div className={classes.grid}>
                    <div className={clsx(classes.columnTitle, classes.row)}>Plan</div>
                    <div className={clsx(classes.columnTitle, classes.row)}>Precio</div>
                    {renderPlan()}
                </div>
            </div>
        </div>
    );
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    table: {
        marginBottom: 20,
        border: `1px solid ${colors.silver}`,
        [theme.breakpoints.up(breakpoints.sm)]: {
            width: 600,
        },
        [theme.breakpoints.down(breakpoints.sm)]: {
            width: '100%',
        },
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)'
    },
    priceColumn: {
        textAlign: 'right',
    },
    highlight: {
        backgroundColor: colors.silver,
    },
    row: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    columnTitle: {
        backgroundColor: colors.darkGray,
        fontWeight: 500,
        color: 'white',
    },
    tableTitle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 18,
        color: colors.darkGray,
    }
});

export default withStyles(styles)(PlanBox);