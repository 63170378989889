import React from 'react';
import { MainContainer, Header, Navigation, Footer } from '../../components/common';
import { GoogleApiWrapper } from 'google-maps-react';
import { Map, InfoWindow, Marker } from 'google-maps-react';

import { withStyles } from '@material-ui/styles';
import { colors } from '../../theme';

const Directions = props => {
    const { classes, google } = props;
    const containerStyle = {
        width: '100%',
        height: 500,
        position: 'relative',
    }
    return (
        <MainContainer>
            <Header />
            <Navigation />
            <div className={classes.container}>
                <Map google={google} zoom={16}
                    containerStyle={containerStyle}
                    initialCenter={{ lat: 18.449448, lng: -69.606248 }}
                >
                    <Marker onClick={() => null}
                        name={'Current location'} />

                    <InfoWindow onClose={() => null}>
                        <div>
                            <h1>Calypso</h1>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
            <Footer />
        </MainContainer>
    );
}

const styles = () => ({
    container: {
        padding: 12,
        textAlign: 'justify',
    },
    title: {
        color: colors.darkGray,
    },
    map: {
        width: 300,
    }
});
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDptVS1YA7zvwnsTNKK8JD-IZH_rVYytfI'
})(withStyles(styles)(Directions));