import React from 'react';
import _ from 'lodash';
import { MainContainer, Header, Navigation, Footer } from '../../components/common';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { withStyles } from '@material-ui/styles';
import { Images } from '../../assets';
import { colors } from '../../theme';

const Pictures = props => {
    const { classes } = props;
    const [selectedImage, setSelectedImage] = React.useState(null);
    const openPicture = el => () => {
        setSelectedImage(el);
    }
    const closePicture = () => {
        setSelectedImage(null);
    }
    const renderImages = () => {
        const images = [];
        for (let i = 1; i <= 14; i++) {
            images.push({ key: i, value: Images[`img${i}`] });
        }
        return images.map(el =>
            <img className={classes.image}
                key={el.key} src={el.value}
                alt="Calypso"
                onClick={openPicture(el)}
            />)
    }
    return (
        <MainContainer>
            <Header />
            <Navigation />
            <div className={classes.container}>
                <h2 className={classes.title}>Fotos</h2>
                <div className={classes.imageContainer}>
                    {renderImages()}
                </div>
                <Drawer anchor="bottom" open={!_.isEmpty(selectedImage)} onClose={closePicture} className={classes.drawer}>
                    <div className={classes.fabContainer}>
                        <Fab color="primary" aria-label="close" className={classes.fab} onClick={closePicture}>
                            <FontAwesomeIcon icon={faTimes} size='2x' />
                        </Fab>
                    </div>
                    {selectedImage && <div className={classes.fullImageContainer}>
                        <img className={classes.image}
                            key={selectedImage.key} src={selectedImage.value}
                            alt="Calypso"
                        />
                    </div>}
                </Drawer>
            </div>
            <Footer />
        </MainContainer>
    );
}

const styles = () => ({
    container: {
        padding: 12,
        textAlign: 'justify',
    },
    title: {
        color: colors.darkGray,
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    imageContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, auto)',
        gridColumnGap: 5,
        gridRowGap: 5,
    },
    fullImageContainer: {
        margin: 10,
        marginTop: -25,
    },
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fabContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 10,
    },
});
export default withStyles(styles)(Pictures);