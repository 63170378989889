import React from 'react';
import { MainContainer, Header, Navigation, PictureFrame, Footer } from '../../components/common';
import { withStyles } from '@material-ui/styles';
import { Images } from '../../assets';
import { colors } from '../../theme';

const FrontPage = props => {
    const { classes } = props;
    return (
        <MainContainer>
            <Header />
            <Navigation />
            <PictureFrame imageNames={['img8', 'img10', 'img9', 'img12']} />
            <div className={classes.container}>
                <h2 className={classes.title}>Calypso Beach Hotel</h2>
                <p>
                    A unos doscientos metros de la orilla de la hermosa playa de Boca Chica, el Calypso Beach Hotel ofrece sus acogedoras habitaciones, sencillas y dobles, con todas las amenidades de un acomodamiento. Atardecer de primera para satisfacer el exigente gusto de los que seleccionan el legendario poblado de Boca Chica, destino turístico de fama mundial, para disfrutar de sus vacaciones.
                </p>
                <p>
                    Nuestros huéspedes cuentan con habitaciones hoteleras con televisión con control remoto, salón de billar, nevera, internet Wi-Fi en áreas comunes, sistema telefónico, aire acondicionado Split, cajas de seguridad, piscina, restaurante, bar, seguridad y servicio de recepción permanente.
                </p>
                <p>
                    Permítanos ser su anfitrión en Boca Chica. Se sentirá usted rodeado de amigos disfrutando de unas vacaciones inolvidables en la que se cumplirán todos sus sueños.
                </p>
                <p>
                    Estamos ubicados a 15 minutos de El Aeropuerto Internacional de Las Américas, y a 40 minutos de Santo Domingo. <a href="https://www.google.com/maps/dir/Aeropuerto+Las+Am%C3%A9ricas+Santo+Domingo,+Ruta+66,+Santo+Domingo/Calypso+Beach+Hotel/@18.4426525,-69.6613149,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8ea57ffc70aeec57:0x59877f3509a574ed!2m2!1d-69.6769171!2d18.4301519!1m5!1m1!1s0x8eaf7e38884ee8e5:0x92124dac2b5460df!2m2!1d-69.6061859!2d18.4494334" target="_blank" rel="noopener noreferrer">Ver ruta en mapa</a>
                </p>
                <h2 className={classes.title}>Sobre Boca Chica</h2>

                <p>
                    La playa de Boca Chica ha desarrollado arena fina. Se puede caminar en el agua y la profundidad apenas se siente, el agua no llega más de la cintura (en una persona de estatura promedio). Es la más familiar de todas las playas de la República Dominicana. Desde la década de los 90, la ciudad ha sido concurrida por turistas de América del Norte y Europa, especialmente durante los meses de diciembre a abril. Es uno de los mejores lugares para disfrutar de la natación en las aguas cálidas del Caribe.
                </p>
                <p>
                    La playa es muy conocida por sus negocios informales y por sus "productos particulares", tales como los yaniqueques, el pescado frito, la mamajuana, entre otros. Existen también tiendas pequeñas, bares, restaurantes con vista al mar, pizzerías, puestos de souvenires y música caribeña y del mundo.
                </p>
                <p>
                    <img src={Images.beach} alt="Playa de Boca Chica" width="100%" />
                </p>
            </div>
            <Footer />
        </MainContainer>
    );
}

const styles = () => ({
    container: {
        padding: 12,
        textAlign: 'justify',
    },
    title: {
        color: colors.darkGray,
    },
});
export default withStyles(styles)(FrontPage);