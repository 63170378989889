import React from 'react';
import { withStyles } from '@material-ui/styles';
import { colors } from '../../theme';


const Footer = ({ classes }) => {
    return (
        <footer className={classes.container}>
            <p className={classes.contactInfo}>Dirección: Calle Caracol Esq. 20 de Diciembre, Boca Chica, Santo Domingo, República Dominicana</p>
            <p className={classes.contactInfo}>Teléfono: 809-523-4666 | Correo electrónico: info@calypsobeachhotel.com</p>
        </footer>
    );
}


const styles = () => ({
    container: {
        textAlign: 'center',
        backgroundColor: colors.silver,
        paddingTop: 2,
        paddingBottom: 2,
    },
    contactInfo: {
        lineHeight: 1,
        fontSize: 14,
    }
});

export default withStyles(styles)(Footer);