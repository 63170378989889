import React from 'react';
import { MainContainer, Header, Navigation, PictureFrame, Footer } from '../../components/common';
import { withStyles } from '@material-ui/styles';
import { colors } from '../../theme';

const Reservations = props => {
    const { classes } = props;
    return (
        <MainContainer>
            <Header />
            <Navigation />
            <PictureFrame imageNames={['img12', 'img10', 'img9', 'img8']} />
            <div className={classes.container}>
                <h2 className={classes.title}>Reservaciones</h2>

                <p>
                    Para reservar, llámenos al teléfono 809-523-4666 o escríbamos al correo electrónico reserv@calypsobeachhotel.com
                </p>
                <p>
                    Favor tener a mano o incluir la siguiente información:
                </p>
                <ul>
                    <li>Fecha en la que planea llegar</li>
                    <li>Fecha en la que planea salir</li>
                    <li>Cantidad de habitaciones a reservar</li>
                    <li>Cantidad de personas por habitación</li>
                    <li>Tipo de habitación deseada</li>
                </ul>
                <p>
                    En el caso de las reservaciones electrónicas le estaremos dando respuesta a más tardar en 24 horas, comprobándole nuestra disponibilidad
                </p>
            </div>
            <Footer />
        </MainContainer>
    );
}

const styles = () => ({
    container: {
        padding: 12,
        textAlign: 'justify',
    },
    title: {
        color: colors.darkGray,
    },
});
export default withStyles(styles)(Reservations);