import React from 'react';
import { MainContainer, Header, Navigation, PictureFrame, Footer } from '../../components/common';
import PlanBox from '../../components/PlanBox';
import { withStyles } from '@material-ui/styles';
import { plans } from '../../assets';
import { colors } from '../../theme';

const Prices = (props) => {
    const { classes } = props;

    const renderPlans = () => {
        return plans.map((plan, idx) => <PlanBox key={idx} plan={plan} />);
    };
    return (
        <MainContainer>
            <Header />
            <Navigation />
            <PictureFrame imageNames={['img9', 'img10', 'img8', 'img12']} />
            <div className={classes.container}>
                <h2 className={classes.title}>Precios</h2>
                {renderPlans()}
            </div>
            <Footer />
        </MainContainer>
    );
};

const styles = () => ({
    container: {
        padding: 12,
        textAlign: 'justify'
    },
    title: {
        color: colors.darkGray
    }
});
export default withStyles(styles)(Prices);
