import Logo from './logos/logo.png';
import Images from './images';
import plans from './data/plans.json';

const Logos = {
    Logo
};


export { Logos, Images, plans };
