const colors = {
    darkGray: '#404e67',
    darkest: '#141a25',
    mediumGray: '#5c6a83',
    lightGray: '#57667d',
    silver: '#d8dbe0',
}

export default colors;

