import React from 'react';
import { withStyles } from '@material-ui/styles';
import { colors, breakpoints } from '../../theme';
import { Logos } from '../../assets';

const Header = props => {
    const { classes } = props;
    return (<div className={classes.main}>
        <h1 className={classes.text}>Calypso Beach Hotel, Boca Chica</h1>
        <img className={classes.image} src={Logos.Logo} alt="Logo Calypso" />
    </div>);
}

const styles = theme => ({
    main: {
        height: 120,
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(breakpoints.sm)]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
        }
    },
    image: {
        height: '100%',
    },
    text: {
        fontFamily: '"Kaushan Script", cursive',
        fontSize: 48,
        marginLeft: 20,
        color: colors.darkGray,
        [theme.breakpoints.down(breakpoints.sm)]: {
            display: 'none',
        }
    }
});

export default withStyles(styles)(Header);