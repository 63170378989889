import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { Images } from '../../assets';
import { breakpoints } from '../../theme';

const PictureFrame = props => {
    const { classes, imageNames } = props;
    return (<div className={classes.root}>
        <img className={classes.image} src={Images[imageNames[0]]} alt="Calypso" />
        <img className={clsx(classes.image, classes.opt)} src={Images[imageNames[1]]} alt="Calypso" />
        <img className={clsx(classes.image, classes.opt)} src={Images[imageNames[2]]} alt="Calypso" />
        <img className={clsx(classes.image, classes.opt)} src={Images[imageNames[3]]} alt="Calypso" />
    </div>);
}

const styles = theme => ({
    root: {
        height: 232,
        padding: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, auto)',
        gridGap: 4,
        alignItems: 'center',
        justifyContent: 'spece-between',
        [theme.breakpoints.down(breakpoints.md)]: {
            height: 180,
        },
        [theme.breakpoints.down(breakpoints.sm)]: {
            height: 110,
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
            gridTemplateColumns: 'repeat(2, auto)',
            height: 'auto',
        },
    },
    image: {
        width: '100%',
        borderRadius: 5,
        [theme.breakpoints.down(breakpoints.xs)]: {
            maxWidth: '100%',
            height: 'auto'
        },
    },
    opt: {
        [theme.breakpoints.down(breakpoints.xs)]: {
            display: 'none',
        },
    }
});
export default withStyles(styles)(PictureFrame);