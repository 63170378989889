import React from 'react';
import FrontPage from './containers/FrontPage';
import Reservations from './containers/Reservations';
import Directions from './containers/Directions';
import Pictures from './containers/Pictures';
import Prices from './containers/Prices';

import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

const App = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Router>
        <Switch>
          <Route path="/reservations">
            <Reservations />
          </Route>
          <Route path="/directions">
            <Directions />
          </Route>
          <Route path="/pictures">
            <Pictures />
          </Route>
          <Route path="/prices">
            <Prices />
          </Route>
          <Route path="/">
            <FrontPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  }
});

export default withStyles(styles)(App);

