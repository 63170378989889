import img1 from './132b648b-ddba-4bfd-9529-237d427ab706.jfif';
import img2 from './1a7ecb9f-c522-4cdc-8f87-f48100b4e32b.jfif';
import img3 from './dca36122-140a-49a1-a878-e2803171c39e.jfif';
import img4 from './af4f17c9-1491-499a-b88b-2f1ce725ecf0.jfif';
import img5 from './892702ff-0456-43b4-9075-ecfe06d26352.jfif';
import img6 from './56192719-9057-44e2-9ea8-fa23e7da180a.jfif';
import img7 from './af4f17c9-1491-499a-b88b-2f1ce725ecf0.jfif';
import img8 from './c8c41ded-213f-4db1-890a-81b09aff5a7c.jfif';
import img9 from './f920418d-505c-4da6-8d06-696fe2d4a596.jfif';
import img10 from './e1fab016-742c-4637-bf80-dd1f87fa4c10.jfif';
import img11 from './e3445f51-2081-4049-8f6d-2685f38c17ee.jfif';
import img12 from './ed24616d-ca85-4cea-9ce3-c5ad780c0b2b.jfif';
import img13 from './f4df2774-5b98-4772-b7df-8c10f12b304f.jfif';
import img14 from './dca36122-140a-49a1-a878-e2803171c39e.jfif';
import beach from './IMG_20131110_090820.jpg';

export default { img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, beach };